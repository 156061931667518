import { emailIcon, whatsappIconTransparentBg } from "~/assets/assets";

export function WhatsappTip() {
  return (
    <div class="inline-flex h-10 w-full items-center justify-start gap-2">
      <img class="h-10" src={whatsappIconTransparentBg} alt="Whatsapp icon" />
      <div class="shrink grow basis-0  text-medium text-textDark">
        The gift card will be ⚡️instantly delivered here and to your WhatsApp
        as well
      </div>
    </div>
  );
}

export function EmailTip() {
  return (
    <div class="inline-flex h-10 w-full items-center justify-start gap-2">
      <img class="h-10" src={whatsappIconTransparentBg} alt="Whatsapp icon" />
      <div class="shrink grow basis-0  text-medium text-textDark">
        The gift card will be ⚡️instantly delivered here and to your whatsapp
        and email as well
      </div>
    </div>
  );
}
